const PublicHeader = () => {
    return (<header className="py-4 nav-header">
        <div className="mx-auto max-w-7xl px-6 sm:px-6 lg:px-8">
            <nav className="relative z-50 flex justify-between">
                <div className="flex items-center md:gap-x-12">
                    <a className="text-blue-600 text-3xl" aria-label="Home" href="/"><img className="max-w-16" src="/img/logo-adn.png" alt="logo ADN électricien"></img></a>
                    <div className="hidden md:flex md:gap-x-6">
                        <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#certifications">Certifications</a>
                        <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#prestations">Prestations</a>
                        <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#contact">Contact</a>
                    </div>
                </div>
                <div className="flex items-center gap-x-5 md:gap-x-8">
                    <a className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600" color="blue" variant="solid" href="/login">Connexion</a>
                </div>
            </nav>
        </div>
    </header>)
}

export default PublicHeader