import { useGetLinksQuery } from "./linksApiSlice"
import Link from "./Link"
import CustomerLink from "./CustomerLink"
import useAuth from "../../hooks/useAuth"
import useTitle from "../../hooks/useTitle"
import PulseLoader from 'react-spinners/PulseLoader'

const LinksList = () => {
    useTitle('ADN : Liste d\'ensembles d\'immeubles')

    const { username, isAdmin } = useAuth()

    const {
        data: links,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetLinksQuery('linksList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = links

        let filteredIds
        if (isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(linkId => entities[linkId].username === username)
        }

        const tableContent = ids?.length && filteredIds.map(linkId => <Link key={linkId} linkId={linkId} />)

        content = (
            <>
                <h1 className="mt-6 mb-6 font-display text-xl text-slate-900">Liste des ensembles d'immeubles</h1>
                <div className="overflow-x-auto">
                    <table className="table table--notes min-w-full divide-y divide-gray-300">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th note__status py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">État</th>
                                <th scope="col" className="table__th note__created px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Créé le</th>
                                <th scope="col" className="table__th note__updated px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MAJ le</th>
                                <th scope="col" className="table__th note__title px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ensemble d'immeubles</th>
                                <th scope="col" className="table__th note__username px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Assigné à</th>
                                <th scope="col" className="table__th note__edit relative py-3.5 pl-3 pr-4 sm:pr-3"><span className="sr-only">Modifier</span></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    return content
}

const CustomerLinksList = () => {

    const { username, isAdmin } = useAuth()

    const {
        data: links,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetLinksQuery('linksList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = links

        let filteredIds
        if (isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(linkId => entities[linkId].username === username)
        }

        const linksContent = ids?.length && filteredIds.map(linkId => <CustomerLink key={linkId} linkId={linkId} />)

        content = (
            linksContent.length > 0 ?
             (<div className="customer-notes-container">
                <h2 className="mt-20 mb-6 font-display text-xl text-slate-900">Vos ensembles d'immeubles :</h2>
                <div className="overflow-x-auto">
                    <table className="table table--users min-w-full divide-y divide-gray-300">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th user__username table__th note__status py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Ensemble</th>
                                <th scope="col" className="table__th user__roles px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Lien synchroteam</th>
                                <th scope="col" className="table__th user__roles px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Statut</th>
                            </tr>
                        </thead>
                        <tbody>
                            {linksContent}
                        </tbody>
                    </table>
                </div>
            </div>) : <p className="mt-8 mb-3 block text-sm font-medium text-gray-700">Vous n'avez aucun ensemble d'immeubles actif.</p>
        )
    }

    return content
}
export default LinksList
export { CustomerLinksList }