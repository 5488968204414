import { useRef, useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'

const Login = () => {
    useTitle('Connexion compte client')

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            navigate('/dash')
        } catch (err) {
            if (!err.status) {
                setErrMsg('Pas de réponse du server');
            } else if (err.status === 400) {
                setErrMsg('Nom d\'utilisateur ou mot de passe manquant');
            } else if (err.status === 401) {
                setErrMsg('Nom d\'utilisateur ou mot de passe erroné');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <section className="public">
            <div className="relative flex min-h-full shrink-0 justify-center md:px-12 lg:px-0" style={{minHeight: '100vh'}}>
                <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
                    <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
                        <div className="flex"><a className="text-blue-600 text-3xl" aria-label="Accueil" href="/"><img className="max-w-16" src="/img/logo-adn.png" alt="logo ADN électricien"></img></a></div>
                        <h2 className="mt-20 text-lg font-semibold text-gray-900">Connectez-vous à votre espace</h2>
                        <p className="mt-2 text-sm text-gray-700">Un problème pour vous connecter ? <a className="font-medium text-blue-600 hover:underline" href="/contact">Contactez-nous</a></p>
                        <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                        <form onSubmit={handleSubmit} className="mt-10 grid grid-cols-1 gap-y-8">
                            <div>
                                <label htmlFor="username" className="mb-3 block text-sm font-medium text-gray-700">Nom d'utilisateur</label>
                                <input
                                    type="text"
                                    id="username"
                                    ref={userRef}
                                    value={username}
                                    onChange={handleUserInput}
                                    autoComplete="off"
                                    required
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"/>
                            </div>
                            <div>
                                <label htmlFor="password" className="mb-3 block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                    type="password"
                                    id="password"
                                    onChange={handlePwdInput}
                                    value={password}
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="persist" className="form__persist">
                                    <input
                                        type="checkbox"
                                        className="form__checkbox"
                                        id="persist"
                                        onChange={handleToggle}
                                        checked={persist}
                                    />
                                    &nbsp;Faire confiance à cet appareil
                                </label>
                            </div>
                            <div>
                                <button className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 w-full" type="submit" variant="solid" color="blue"><span>Connexion <span aria-hidden="true">→</span></span>
                                </button>
                            </div>
                        </form>
                    </main>
                </div>
                <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
                    <img alt="" loading="lazy" width="1664" height="1866" decoding="async" data-nimg="1" className="absolute inset-0 h-full w-full object-cover" src="/img/fond-login.jpg" style={{color: 'transparent'}}/>
                </div>
            </div>
        </section>
    )

    return content
}
export default Login