import { Container } from './Container.js'

export default function Footer() {
  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <a className="text-blue-600 text-3xl flex justify-center" aria-label="Home" href="/">ADN</a>
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#certifications">Certifications</a>
              <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#prestations">Prestations</a>
              <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="#contact">Contact</a>
              <a className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" href="/login">Compte client</a>
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            <p className="text-center mt-6 text-sm text-slate-500 sm:mt-0 sm:text-start">
                SARL A.D.N.<br/>
                43 rue d'Hautpoul, 75019, Paris<br/>
                419 910 278 R.C.S. Paris
            </p>
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright &copy; {new Date().getFullYear()} ADN.
          </p>
        </div>
      </Container>
    </footer>
  )
}