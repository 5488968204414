import { useGetNotesQuery } from "./notesApiSlice"
import CustomerNote from "./CustomerNote"
import useAuth from "../../hooks/useAuth"
import PulseLoader from 'react-spinners/PulseLoader'

const CustomerNotesList = () => {

    const { username, isAdmin } = useAuth()

    const {
        data: notes,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetNotesQuery('notesList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids, entities } = notes

        let filteredIds
        if (isAdmin) {
            filteredIds = [...ids]
        } else {
            filteredIds = ids.filter(noteId => entities[noteId].username === username && !entities[noteId].completed)
        }

        const notesContent = ids?.length && filteredIds.map(noteId => <CustomerNote key={noteId} noteId={noteId} />)

        content = (
            notesContent.length > 0 ?
             (<div className="customer-notes-container">
                <h2 className="mt-10 mb-6 font-display text-xl text-slate-900">Vos messages :</h2>
                {notesContent}
            </div>) : <p className="mt-8 mb-3 block text-sm font-medium text-gray-700">Vous n'avez pas reçu de messages de ADN.</p>
        )
    }

    return content
}
export default CustomerNotesList