import { Container } from './Container.js'
const certifications = [
    {
      name: 'QUALIFELEC',
      role: 'Installateur électrique',
      imageUrl:
        'https://www.qualifelec.fr/wp-content/uploads/2018/09/logo_home.png',
      xUrl: 'https://www.qualifelec.fr/'
    },
    {
        name: 'ODE FORMATION PB',
        role: 'Prévention risque plomb',
        imageUrl:
          'https://www.odeformation.com/img/FX.jpg',
        xUrl: 'https://www.odeformation.com/formation-prevention-risque-plomb.php'
      },
      {
        name: 'ODE FORMATION SS4',
        role: 'Prévention risque amiante',
        imageUrl:
          'https://www.odeformation.com/img/SS4.jpg',
        xUrl: 'https://www.odeformation.com/formation-prevention-risque-amiante-ss4.php'
      },
      {
        name: 'ACOVA',
        role: 'Partenaire',
        imageUrl:
          'https://www.acova.fr/themes/custom/acova/logo.svg',
        xUrl: 'https://www.acova.fr/trouver-un-professionnel'
      }
  ]
  
export function Certifications() {
    return (
        <section
            id="certifications"
            aria-label="Nos certifications"
            className="relative pb-28 pt-20 sm:py-22"
        >
            <Container className="relative">
                <div className="max-w-3xl md:mx-auto md:text-center xl:max-w-none">
                    <h2 className="font-display text-3xl tracking-tight text-blue-600 sm:text-4xl md:text-5xl">
                        Nos certifications
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                    Le maintient des certifications nous permet de travailler dans la mise en oeuvre des évolutions réglementaires. La formation du collectif de l'entreprise est continue.
                    </p>
                </div>
                <ul
                    className="mx-auto mt-20 grid max-w-2xl grid-cols-2 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4"
                >
                    {certifications.map((certification) => (
                    <li key={certification.name}>
                        <img alt="" src={certification.imageUrl} className="aspect-[3/2] w-full rounded-2xl object-contain" />
                        <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{certification.name}</h3>
                        <p className="text-base leading-7 text-gray-600">{certification.role}</p>
                        <ul className="mt-6 flex gap-x-6">
                        <li>
                            <a href={certification.xUrl} className="text-blue-600 hover:text-gray-500">
                                En savoir plus
                            </a>
                        </li>
                        </ul>
                    </li>
                    ))}
                </ul>
            </Container>
        </section>
    )
}
